<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Add Pop Up
        <div class="card-header-actions">
          <a class="card-header-action" href="pop-up-beli-emas" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" novalidate action="https://vuejs.org/">
              <b-form-group id="userNameInputGroup3"
                            label="Image"
                            label-for="userName">
                <b-form-file id="selectedImage"
                              :plain="true"
                              accept="image/*"
                              aria-describedby="imageFeedback"
                              v-model.lazy.trim="$v.form.selectedImage.$model"
                              :state="chkState('selectedImage')"
                              @change="onFileSelected">
                </b-form-file>
              </b-form-group>
              <b-form-group id="type"
                        label="Type"
                        label-for="type">
                <b-form-select id="type"
                  v-model="form.type"
                  :plain="true"
                  aria-describedby="typeFeedback"
                >
                  <option value="">Pilih Tipe Popup</option>
                  <option v-for="(item, index) in this.type" :key="index" :value="item.value">{{ item.text }}</option>
                </b-form-select>
                <b-form-invalid-feedback id="typeFeedback">
                  <span v-if="!$v.form.type.required">- Tipe Popup tidak boleh kosong.</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Status"
                  label-for="basicInlineCheckboxess">
                <b-form-checkbox-group id="basicInlineCheckboxess" name="InlineCheckboxes" :plain="true" v-model="status">
                  <b-form-checkbox :plain="true" value="0">Active</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
              <b-form-group id="link"
                              label="Link to Article"
                              label-for="link_to">
                  <b-form-input id="link_to"
                              type="text"
                              v-model="form.link"
                              placeholder="Please Enter Pop Up Link"
                              autofocus />
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

export default {
  name: "Add-Popup",
  data() {
    return {
      form: {
        selectedImage: '',
        link: '',
        type: ''
      },
      status: '',
      type: [],
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          status: [],
          image: [],
        },
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      selectedImage: {
        required
      },
      type: {
        required
      }
    }
  },
  created() {
    this.$http.get('popup-type').then((res) => {
      let result = res.data.data
      let data = result.map((v) => {
        let name = v.name.replace(/[_-]/g, " ");

        return { value: v.hashed_id, text: name.toUpperCase() }
      })
      this.type = data
    })
  },
  methods: {
    onFileSelected(event) {
      this.form.selectedImage = event.target.files[0];
    },
    onSubmit() {
        this.validate()
        this.isLoading = true
          const formData =  new FormData();
          if (this.form.selectedImage == '') {
            formData.append('image', '')
          }else{
            formData.append('image', this.form.selectedImage, this.form.selectedImage.name)
          }
          formData.append('type', this.form.type)
          formData.append("status", this.status === '' ? 1 : this.status)
          formData.append("link", this.form.link)
          this.$http.post(`popup`, formData)
          .then((result) => {
            this.isLoading = false
            this.$router.push("/pop-up");
            this.$toasted.success(result.data.meta.message)
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if(this.errors.status == 422) {
                this.$toasted.error('Please fill out the form that must be required')
                this.errors.message = error.response.data.meta.message;
                this.errors.form.title = this.errors.message.title;
                this.errors.form.content = this.errors.message.content;
                this.errors.form.image = this.errors.message.image;
                this.errors.form.category = this.errors.message.category;
                this.errors.form.related = this.errors.message.type_id;
                this.errors.form.type = this.errors.message.type
              }else if (this.errors.status == 400) {
                this.$swal.fire(
                  'Failed!',
                  error.response.data.meta.message,
                  'error'
                )
              }
            }
          })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.preview {
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  width: 50%;
  height: 50%;
}
.image-preview {
  padding: 20px;
}

.invalid-feedback {
    font-size: 100% !important;
}
.notValidate {
  border: #f86c6b 1px solid;
}
.validate {
  border: green 1px solid;
}
#contentFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}

</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
